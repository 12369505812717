
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.33073 4.99967V3.66634C9.33073 1.79967 7.86406 0.333008 5.9974 0.333008C4.13073 0.333008 2.66406 1.79967 2.66406 3.66634V4.99967C1.53073 4.99967 0.664062 5.86634 0.664062 6.99967V11.6663C0.664062 12.7997 1.53073 13.6663 2.66406 13.6663H9.33073C10.4641 13.6663 11.3307 12.7997 11.3307 11.6663V6.99967C11.3307 5.86634 10.4641 4.99967 9.33073 4.99967ZM3.9974 3.66634C3.9974 2.53301 4.86406 1.66634 5.9974 1.66634C7.13073 1.66634 7.9974 2.53301 7.9974 3.66634V4.99967H3.9974V3.66634ZM6.73073 9.33301L6.66406 9.39968V10.333C6.66406 10.733 6.3974 10.9997 5.9974 10.9997C5.5974 10.9997 5.33073 10.733 5.33073 10.333V9.39968C4.93073 8.99967 4.86406 8.39967 5.26406 7.99967C5.66406 7.59967 6.26406 7.53301 6.66406 7.93301C7.06406 8.26634 7.13073 8.93301 6.73073 9.33301Z" fill="#1D2433" fill-opacity="0.65"/>
</svg>

  </template>

  <script>
  export default {
    name: 'IconLock',
    inheritAttrs: true,
  }
  </script>
